<template>
    <!-- //简单表格 -->
    <div class="Short_form">
        <el-table :data="tabledata" style="width: 100%" :stripe="true" :height="high" empty-text="暂无数据"
            :row-style="{ height: row_high }" :header-row-style="{ height: row_high == '84px' ? row_high : '' }">
            <el-table-column v-for="(item, index) in construction" :key="index" :prop="item.fieldName" :label="item.title"
                align="center" :formatter="slashType == 'close' ? undefined : formatCleanliness">

            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['construction', 'tabledata', 'high', 'row_high', 'slashType'],
    data() {
        return {};
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        formatCleanliness(row, column, cellValue, index) {
            if (cellValue === 0 || cellValue === '0%' || cellValue === '') {
                return '/';
            } else {
                return cellValue;
            }
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
    },
    beforeCreate() { }, //生命周期：创建之前
    beforeMount() { }, //生命周期：挂载之前
    beforeUpdate() { }, //生命周期：更新之前
    updated() { }, //生命周期：更新之后
    beforeDestroy() { }, //生命周期：销毁之前
    destroyed() { }, //生命周期：销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less">
.Short_form {
    // width: 696px;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #e4e4e4;
        border-radius: 3px;
    }

    //滚动条的滑块
    ::-webkit-scrollbar-thumb {
        background-color: #c8c9cc;
        border-radius: 3px;
    }

    // height: 288px;

    /deep/.el-table {
        border-radius: 10px;
        border: 1px solid #E8E8E8;

        .el-table__body {
            width: 100% !important;
        }

        th {
            text-align: center;
            background: #F2FBF9;
            color: #01B58C;
        }
    }
}
</style>