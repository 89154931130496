<!-- 全校各等级学员占比(新版) -->
<template>
    <div class="schoolLevel">
        <el-table :data="tableData" style="width: 100%" border>
            <el-table-column label="等级" align="center">
                <template slot-scope="scope">
                    {{ levle[scope.row.level] }}
                </template>
            </el-table-column>
            <el-table-column label="居中占位" align="center">
                <template slot-scope="scope">
                    {{ scope.row.center_pos }}%
                </template>
            </el-table-column>
            <el-table-column label="占格大小" align="center">
                <template slot-scope="scope">
                    {{ scope.row.grid_size }}%
                </template>
            </el-table-column>
            <el-table-column label="字形重心" align="center">
                <template slot-scope="scope">
                    {{ scope.row.glyph_grav }}%
                </template>
            </el-table-column>
            <el-table-column label="组合关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.combo_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="收放关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.rewind_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="多画等距" align="center">
                <template slot-scope="scope">
                    {{ scope.row.multi_dist }}%
                </template>
            </el-table-column>
            <el-table-column label="笔画关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.stroke_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="作品完整度" align="center">
                <template slot-scope="scope">
                    {{ scope.row.integrity }}%
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    props: ['termValue', 'reportType', 'reportCode'],
    data() {
        return {
            tableData: [],
            // levle: {
            //     '4': '优秀+',
            //     '3': '优秀',
            //     '2': '良好',
            //     '1': '待提升',
            // }
            levle: {
                '4': '优秀',
                '3': '良好',
                '2': '合格',
                '1': '待提升',
            }
        }
    },
    watch: {
        termValue: {
            handler(newValue, oldValue) {
                if (newValue) {
                    // console.log('termValue');
                    
                    // console.log('newValue',newValue);
                    // console.log('oldValue',oldValue);
                    this.studentLvRatioCompareV2()
                }
               
                
               
            },
            deep: true,
            immediate: true
        },
        reportType: {
            handler(newValue, oldValue) {
              
                if (newValue) {
                    // console.log('reportType');
                    // console.log('newValue',newValue);
                    // console.log('oldValue',oldValue);
                    // this.studentLvRatioCompareV2()
                }
                
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 全校各等级学员占比
        studentLvRatioCompareV2() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testType: this.reportType,
                testTypeCode: this.reportCode
            }
            this.$Api.Report.studentLvRatioCompareV2(data)
                .then(res => {
                    console.log('新版全校各等级学员占比:', res);
                    this.tableData = res.data.resultList
                })
                .catch(err => {
                    // console.log(err)
                })
        },
    },
    mounted() {
    }
}
</script>
<style lang="less" scoped>
</style>