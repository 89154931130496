<template>
    <div class="participation" id="pdf6">
        <div class="cephalosome flex-align-between">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">全校各项成绩对比</span>
            </div>
        </div>
        <div class="substance  School_substance">
            <div class="performance_proportion performance_proportion_new table_scrollbar">
                <el-table :data="tablaData_school" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="维度" align="center">
                    </el-table-column>
                    <el-table-column prop="centerPos" label="居中占位" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.centerPos == undefined ? '/' :
                                scope.row.centerPos.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="gridSize" label="占格大小" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gridSize == undefined ? '/' :
                                scope.row.gridSize.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="glyphGrav" label="字形重心" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.glyphGrav == undefined ? '/' :
                                scope.row.glyphGrav.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="comboRel" label="组合关系" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.comboRel == undefined ? '/' :
                                scope.row.comboRel.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rewindRel" label="收放关系" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.rewindRel == undefined ? '/' :
                                scope.row.rewindRel.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="multiDist" label="多画等距" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.multiDist == undefined ? '/' :
                                scope.row.multiDist.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="strokeRel" label="笔画关系" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.strokeRel == undefined ? '/' :
                                scope.row.strokeRel.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="integrity" label="作品完整度" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.integrity == undefined ? '/' :
                                scope.row.integrity.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="performance_graphics performance_graphics_new">
                <various_graphics :seriesData="various_graphics_data" :variousData="variousData" :variousType="'月测'">
                </various_graphics>
            </div>
        </div>
        <div class="semester flex-column-center">
            <h1>与上次周测成绩对比</h1>
            <div class="semester_table table_scrollbar semester_table_new">
                <el-table :data="tableData_compare" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="上次周测对比" align="center" width="80"> </el-table-column>
                    <el-table-column label="居中占位" align="center">
                        <el-table-column prop="centerPos" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}` || 'def'"
                                    v-if="scope.row.centerPos_compare && scope.row.centerPos != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.centerPos_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.centerPos ==
                                    0 ? '/' :
                                    scope.row.centerPos }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="centerPosLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.centerPosLast == 0 ? '/' : scope.row.centerPosLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="占格大小" align="center">
                        <el-table-column prop="gridSize" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.gridSize_compare && scope.row.gridSize != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.gridSize_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.gridSize ==
                                    0 ? '/' :
                                    scope.row.gridSize }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gridSizeLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.gridSizeLast == 0 ? '/' : scope.row.gridSizeLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="字形重心" align="center">
                        <el-table-column prop="glyphGrav" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.glyphGrav_compare && scope.row.glyphGrav != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.glyphGrav_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.glyphGrav ==
                                    0 ? '/' :
                                    scope.row.glyphGrav }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="glyphGravLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.glyphGravLast == 0 ? '/' : scope.row.glyphGravLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="组合关系" align="center">
                        <el-table-column prop="comboRel" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.comboRel_compare && scope.row.comboRel != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.comboRel_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.comboRel ==
                                    0 ? '/' :
                                    scope.row.comboRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="comboRelLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.comboRelLast == 0 ? '/' : scope.row.comboRelLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="收放关系" align="center">
                        <el-table-column prop="rewindRel" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.rewindRel_compare && scope.row.rewindRel != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.rewindRel_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.rewindRel ==
                                    0 ? '/' :
                                    scope.row.rewindRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rewindRelLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.rewindRelLast == 0 ? '/' : scope.row.rewindRelLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="多画等距" align="center">
                        <el-table-column prop="multiDist" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.multiDist_compare && scope.row.multiDist != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.multiDist_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.multiDist ==
                                    0 ? '/' :
                                    scope.row.multiDist }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="multiDistLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.multiDistLast == 0 ? '/' : scope.row.multiDistLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="笔画关系" align="center">
                        <el-table-column prop="strokeRel" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.strokeRel_compare && scope.row.strokeRel != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.strokeRel_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.strokeRel ==
                                    0 ? '/' :
                                    scope.row.strokeRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="strokeRelLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.strokeRelLast == 0 ? '/' : scope.row.strokeRelLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="作品完整度" align="center">
                        <el-table-column prop="integrity" label="本次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" :class="`imgUrl${scope.row.comboRel_compare}`"
                                    v-if="scope.row.integrity_compare && scope.row.integrity != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.integrity_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ scope.row.integrity ==
                                    0 ? '/' :
                                    scope.row.integrity }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="integrityLast" label="上次周测" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.integrityLast == 0 ? '/' : scope.row.integrityLast }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="semester_conclusion conclusion" v-if="comment5">
            <span class="prefix_title">结论3</span><br />
            <span>
                (1) 居中占位维度：平均成绩处于<span class="Comment_highlight">{{ comment5.centerPos }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'centerPos') }}较好</span>。<br />
                (2) 占格大小维度：平均成绩处于<span class="Comment_highlight">{{ comment5.gridSize }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'gridSize') }}较好</span>。<br />
                (3) 字形重心维度：平均成绩处于<span class="Comment_highlight">{{ comment5.glyphGrav }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'glyphGrav') }}较好</span>。<br />
                (4) 组合关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5.comboRel }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'comboRel') }}较好</span>。<br />
                (5) 收放关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5.rewindRel }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'rewindRel') }}较好</span>。<br />
                (6) 多画等距维度：平均成绩处于<span class="Comment_highlight">{{ comment5.multiDist }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'multiDist') }}较好</span>。<br />
                (7) 笔画关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5.strokeRel }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'strokeRel') }}较好</span>。<br />
                (8) 作品完整度维度：平均成绩处于<span class="Comment_highlight">{{ comment5.integrity }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'integrity') }}较好</span>。<br />
            </span>
            <p>相较上次周测，在
                <span class="Comment_highlight">居中占位</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId1') }}；
                <span class="Comment_highlight">占格大小</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId2') }}；
                <span class="Comment_highlight">字形重心</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">组合关系</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">收放关系</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">多画等距</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">笔画关系</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">作品完整度</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId4') }}。
            </p>
        </div>
    </div>
</template>

<script>
import various_graphics from './appraisalCharts/various_graphics.vue'
export default {
    //import引入组件才能使用
    components: {
        various_graphics,
    },
    props: {},
    data() {
        return {
            //全校成绩对比
            tablaData_school: [],
            various_graphics_data: [0, 0, 0, 0, 0, 0, 0, 0],
            //雷达图
            variousData: [
                { name: '居中占位', max: 100 },
                { name: '占格大小', max: 100 },
                { name: '字形重心', max: 100 },
                { name: '组合关系', max: 100 },
                { name: '收放关系', max: 100 },
                { name: '多画等距', max: 100 },
                { name: '笔画关系', max: 100 },
                { name: '作品完整度', max: 100 },
            ],
            //与上次月测等级对比
            tableData_compare: [],
            comment5: null,
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        parameterall1(tableData, graphicData) {
            this.tablaData_school = tableData;
            console.log('this.tablaData_school',this.tablaData_school);
            this.various_graphics_data = graphicData;
            console.log('this.various_graphics_data',this.various_graphics_data);
        },
        parameterall2(tableData, commentData) {
            this.tableData_compare = tableData;
            this.comment5 = commentData;
        },
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            let element = [];
            for (let index = 0; index < list.length; index++) {
                element.push(list[index][name]);
            }
            let Mathdata = Math.max(...element)

            const indices = element.reduce((acc, value, index) => {
                if (value === Mathdata) {
                    acc.push(list[index].indexRank);
                }
                return acc;
            }, []);
            if (indices.length <= 3) {
                return indices.join('、');
            } else {
                const firstFour = indices.slice(0, 3);
                const remaining = indices.length - 3;
                return firstFour.join('、') + (remaining > 1 ? '等' : '等');
            }
        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上次周测有所提升`
            }
            return comment
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang="less" scoped>
@import url("./less/monthTest.less");

.performance_proportion {
    // border: 1px solid #000;
    // 818
}

.performance_graphics {
    // border: 1px solid #000;
    // 560
}

.performance_proportion_new {
    width: 978px !important;
}

.performance_graphics_new {
    width: 400px !important;

}

/deep/.semester_table_new {
    .el-table {
        .cell {
            // padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .imgUrl1 {
            width: 20px;
            height: auto;
        }

        .imgUrl2 {}

        .imgUrl3 {}
    }
}</style>