import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import imageUrl from '../assets/base_z.png';
import MSYH from '../assets/font/MSYH-normal.js'
let imageBase64;
const img = new Image();
img.src = imageUrl;
img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    canvas.getContext('2d').drawImage(img, 0, 0);
    imageBase64 = canvas.toDataURL('image/png'); // 转换为base64
};

// const imgModuleData = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg=='; // 这里是你的图片Base64编码
const headerHeight = 13; // 图片页眉的高度
const pageMargins = {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20
}; // 页面四周的边距
export async function convertToPdf(fileName, moduleElements) {
    // console.log('imageBase64', imageBase64);
    // 将new jsPDF()第一个参数改成'l',就可以将pdf改成横版了
    var pdf = new jsPDF('l', 'pt', 'a4', false);
    const headerText = fileName;
    // 获取所有模块元素
    for (const module of moduleElements) {
        // 假设module.content包含模块对应的HTML字符串或者DOM元素
        if (module.content instanceof HTMLElement) {
            //动态计算纸张
            const canvas = await html2canvas(module.content, {
                allowTaint: true,
                useCORS: true,
                imageQuality: 1,
                backgroundColor: '#FFFFFF',
            });
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            // 添加图片页眉
            // pdf.addImage(imageBase64, 'PNG', pageMargins.left + 45, pageMargins.top, 64, headerHeight);
            pdf.addImage(imageBase64, 'PNG', 48, pageMargins.top, 64, headerHeight);
            // pdf.addImage(imgModuleData, 'PNG', 20, 20, 100, headerHeight); // 设置图片位置和大小

            // const maxPageWidth = pdf.internal.pageSize.getWidth();
            const maxPageWidth = pdf.internal.pageSize.getWidth() - pageMargins.left - pageMargins.right;
            // const maxPageHeight = pdf.internal.pageSize.getHeight();
            const maxPageHeight = pdf.internal.pageSize.getHeight() - pageMargins.top - pageMargins.bottom;
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            // 计算合适的缩放比例
            let scaleToFit = Math.min(maxPageWidth / imgWidth, maxPageHeight / imgHeight);
            const scaledWidth = imgWidth * (scaleToFit * 0.97);
            const scaledHeight = imgHeight * (scaleToFit * 0.97);
            // 计算图片的水平和垂直居中位置
            // let xPosition = (maxPageWidth - scaledWidth) / 2;
            // let yPosition = (maxPageHeight - scaledHeight) / 2;
            let xPosition = pageMargins.left + (maxPageWidth - scaledWidth) / 2;
            let yPosition = pageMargins.top + headerHeight + (maxPageHeight - scaledHeight - headerHeight) / 2;
            // 添加图片到PDF
            pdf.addImage(imgData, 'JPEG', xPosition, yPosition + 10, scaledWidth, scaledHeight);
            // 添加页眉到当前页面顶部
            pdf.setFont('MSYH','normal') 
            pdf.setFontSize(11); // 设置字体大小
            pdf.setTextColor(137, 138, 142); // 设置字体颜色（RGB）
            const headerWidth = pdf.getTextWidth(headerText);
            // const headerX = (maxPageWidth - headerWidth) / 2; // 计算水平居中位置
            const headerX = maxPageWidth - pageMargins.right + 23 - headerWidth; // 计算靠右位置
            const headerY = 27; // 页眉距离顶部的距离，可根据实际需求调整
            pdf.text(headerText, headerX, headerY); // 在页眉位置添加文本
            pdf.addPage();
        }
        // 调整页码和布局，根据实际需求决定是否换页
    }

    // 保存并下载PDF文件
    pdf.save(fileName);
}
export default convertToPdf