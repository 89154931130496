<template>
    <div class="various_graphics" id="various_graphics"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {
        seriesData: {
            type: Array,
            require: true
        },
        variousData: {
            type: Array,
            require: true
        },
        variousType: {
            type: String,
            require: true
        },
    },
    data() {
        return {
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        seriesData() {
            this.participation_graphics()
        }
    },
    // 方法集合
    methods: {
        participation_graphics() {
            this.$echarts.init(document.getElementById("various_graphics")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("various_graphics")
            );
            let splitColor = "#fff";
            myChart.setOption({
                tooltip: {
                    show: true,
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    // formatter: '{c0}',
                    // formatter: (params) => {
                    //     console.log('params',params);
                    // }   
                },
                title: {
                    text: '全校各项成绩对比',
                    fontWeight: 'bold',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: '4%',
                    left: '34%'
                },
                radar: {
                    center: ["50%", "56%"], // 外圆的位置
                    // radius: "60%",
                    radius: "50%",
                    axisName: {
                        color: "#033333",
                        fontSize: this.graphicRatio(14),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    // indicator: [{ name: '章法占格', max: 100 },
                    // { name: '作品完整度', max: 100 },
                    // { name: '笔画形态', max: 100 },
                    // { name: '结构组合', max: 100 },],
                    indicator: this.variousData,
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width:this.graphicRatio(1),
                        color: '#9b95f7',
                    },
                    data: [{
                        name: this.variousType,
                        value: this.seriesData,
                        label: {
                            formatter: '{value}%',
                            fontSize: this.graphicRatio(14),
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                        }
                    },
                    ]
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
       
    }
}
</script>
<style scoped lang="less">
.various_graphics {
    width: 100%;
    height: 100%;
}
</style>