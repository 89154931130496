<template>
    <div class="grade_graphics" id="grade_graphics"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {
        seriesData: {
            type: Array,
            require: true
        },
        table_type: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        seriesData() {
            this.participation_graphics()
        }
    },
    // 方法集合
    methods: {
        participation_graphics() {
            this.$echarts.init(document.getElementById("grade_graphics")).dispose();
            let that = this;
            let zData = this.table_type == 2 ? ["优秀", "良好", "合格", "待提升"] : ["优秀+", "优秀-", "良好", "待提升"];
            // let lc = [45, 300, 25, 34];
            let myChart = this.$echarts.init(
                document.getElementById("grade_graphics")
            );
            myChart.setOption({
                grid: {
                    right: "8%",
                    top: "15%",
                    left: "1%",
                    bottom: "8%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: zData,
                    axisLabel: {
                        // textStyle: {
                        color: "#333333",
                        fontSize: this.graphicRatio(14),
                        // },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    }
                },
                yAxis: [
                    {
                        show: false,
                        type: "value",
                    },
                ],
                series: [
                    {
                        name: "占比",
                        type: "pictorialBar",
                        stack: "数量",
                        barWidth: "120%",
                        label: {
                            formatter: '{c}%',
                            show: true,
                            position: "top",
                            // textStyle: {
                            color: "#666666",
                            fontSize: this.graphicRatio(14),
                            // },
                        },
                        itemStyle: {
                            // normal: {
                            color: function (params) {
                                var colorList = [new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(234, 173, 24,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(234, 173, 24,0.1)',
                                }]), new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(53, 236, 165,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(53, 236, 165,0.1)',
                                }]), new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(39, 177, 234,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(39, 177, 234,0.1)',
                                }]), new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(255, 129, 150,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(255, 129, 150,0.1)',
                                }])]
                                return colorList[params.dataIndex]
                            },
                            // },
                        },
                        symbol:
                            "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",
                        data: this.seriesData,
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.participation_graphics();
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less">
.grade_graphics {
    width: 100%;
    height: 100%;
}
</style>