<template>
    <div class="incorrectDemonstration" :id="this.graphicsData.idName">
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['graphicsData', 'termKey'],
    data() {
        return {
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        graphicsData: {
            handler() {
                // console.log('graphicsData变化');
                this.participation_graphics()
            },
            deep: true,
        }
    },
    // 方法集合
    methods: {
        participation_graphics() {
            this.$echarts.init(document.getElementById(this.graphicsData.idName)).dispose();
            let myChart = this.$echarts.init(
                document.getElementById(this.graphicsData.idName)
            );
            myChart.setOption({
                title: {
                    text: this.graphicsData.title,
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: this.graphicRatio(14),
                    },
                    top: '0%',
                    left: '38%'

                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    icon: 'rect',
                    show: true,
                    bottom: '2%',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        // color: ' #d3d3d3',
                        color: "#000",
                        fontSize: this.graphicRatio(12),
                    },
                    itemGap: this.graphicRatio(30),
                },
                grid: [
                    {
                        show: false,
                        top: '12%',
                        left: '54%',
                        right: '10%',
                        containLabel: false

                    },
                    {
                        show: false,
                        top: '12%',
                        left: '10%',
                        right: '56.5%',
                        containLabel: false,
                    },
                ],
                yAxis: [
                    {
                        type: 'category',
                        // data: this.graphicsData.yData,
                        data: this.graphicsData.semester1.map(item => item.name),
                        axisTick: {
                            alignWithLabel: true,

                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            margin: this.graphicRatio(12),
                            fontSize: this.graphicRatio(14),
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#000",
                                // color: "#C2C2C2"
                            }
                        },


                    },
                    {
                        // data: this.graphicsData.yData,
                        data: this.graphicsData.semester1.map(item => item.name),
                        gridIndex: 1,
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                // color: "#C2C2C2"
                                color: "#000",
                            }
                        },
                        axisTick: {
                            show: true,
                            inside: true,
                            alignWithLabel: true
                        },
                        axisLabel: {
                            show: false,

                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                xAxis: [
                    {
                        type: 'value',
                        // 整条y轴
                        axisLine: {
                            show: true,
                            lineStyle: {
                                // color: "#C2C2C2"
                                color: "#000",
                            }
                        },
                        // y轴上的小横线
                        axisTick: {
                            show: true,
                            lineStyle: {
                                // color: "#C2C2C2"
                                color: "#000",
                            }
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: this.graphicRatio(14),
                        },
                    },
                    {
                        gridIndex: 1,
                        type: 'value',
                        inverse: true,
                        // 整条y轴
                        axisLine: {
                            show: true,
                            lineStyle: {
                                // color: "#C2C2C2"
                                color: "#000",
                            }
                        },
                        // y轴上的小横线
                        axisTick: {
                            show: true,
                            lineStyle: {
                                // color: "#C2C2C2"
                                color: "#000",
                            }
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: this.graphicRatio(14),
                        },
                    },
                ],
                series: [
                    {
                        name: '上学期',
                        type: 'bar',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        barWidth: this.graphicRatio(25),
                        stack: '2',
                        itemStyle: {
                            borderRadius: [this.graphicRatio(5), 0, 0, this.graphicRatio(5)], // 设置圆角大小为10px,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: this.graphicsData.colordata[2],
                            }, {
                                offset: 1,
                                color: this.graphicsData.colordata[3],
                            }])
                        },
                        data: this.graphicsData.semester2.map(item => item.value),
                        // data: this.graphicsData.semester2,
                        label: {
                            show: true,
                            position: 'left',
                            // color: "#CECECE"
                            color: "#000",
                            fontSize: this.graphicRatio(14),
                        }
                    },
                    {
                        name: '本学期',
                        type: 'bar',
                        barWidth: this.graphicRatio(25),
                        stack: '1',
                        itemStyle: {
                            borderRadius: [0, this.graphicRatio(5), this.graphicRatio(5), 0], // 设置圆角大小为10px,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: this.graphicsData.colordata[0],
                            }, {
                                offset: 1,
                                color: this.graphicsData.colordata[1],
                            }])
                        },
                        // data: this.graphicsData.semester1,
                        data: this.graphicsData.semester1.map(item => item.value),
                        label: {
                            show: true,
                            position: 'right',
                            // color: "#CECECE"
                            color: "#000",
                            fontSize: this.graphicRatio(14),
                        }
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        // this.participation_graphics();
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less">
.incorrectDemonstration {
    width: 100%;
    height: 100%;
}
</style>