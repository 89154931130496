<template>
    <div class="excellent_rates_grap" :id="'excellent_rates_grap' + this.id_type"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['graphicsData', 'id_type'],
    data() {
        return {
            dom_id: this.id_type
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        graphicsData: {
            handler() {
                this.participation_graphics()
            },
            deep: true,
        }
    },
    // 方法集合
    methods: {
        participation_graphics() {
            let that = this;

            this.$echarts.init(document.getElementById('excellent_rates_grap' + this.dom_id)).dispose();
            let myChart = this.$echarts.init(
                document.getElementById('excellent_rates_grap' + this.dom_id)
            );
            myChart.setOption({
                // tooltip: {
                //     trigger: 'axis',
                //     formatter: "{a} <br/>时间：{b} <br/>百分比: {c}%",
                //     axisPointer: {
                //         type: 'shadow'
                //     }
                // },
                legend: {
                    icon: 'rect',
                    show: true,
                    bottom: '0%',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        // color: "#dbdbdb"
                        color: "#000",
                        fontSize:this.graphicRatio(12),
                    },
                    itemGap: this.graphicRatio(30),
                },
                // dataZoom: [
                //     {
                //         type: 'inside',
                //         startValue: 0,
                //         endValue: 7,
                //         // start: 0,
                //         // end: 100,
                //     },
                // ],
                grid: {
                    top: '10%',
                    left: '5%',
                    right: '2%',
                    bottom: '12%',
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    data: that.graphicsData.yData,
                    axisLine: {
                        lineStyle: {
                            // color: "rgba(117, 168, 202, 0.4)"
                            color: "#000",
                        },
                        show: true
                    },
                    axisTick: {
                        show: true,
                        color: "#dbdbdb"
                    },

                    axisLabel: {
                        color: "#000",
                        // color: "#dbdbdb",
                        fontSize: this.graphicRatio(14),
                        interval: 0,
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        inverse: false,
                        max: 100,
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                // color: "#dbdbdb"
                                color: "#000",
                            }
                        },
                        axisLabel: {
                            show: true,
                            formatter: "{value}",
                            textStyle: {
                                // color: '#dbdbdb'
                                color: "#000",
                                fontSize: this.graphicRatio(14),
                            }
                        },
                        nameTextStyle: {
                            color: 'red',
                            padding: [0, 0, 0, this.graphicRatio(15)]
                        },
                        axisTick: {
                            show: true
                        }
                    },
                ],
                series: [
                    {
                        name: that.graphicsData.title,
                        type: 'bar',
                        barWidth: this.graphicRatio(39),
                        // barWidth: 29,
                        label: {
                            normal: {
                                show: true,
                                // offset:[0,-30],
                                position: 'top',
                                // position: ['20%','-60%'],
                                formatter: '{c}%',
                                fontSize: this.graphicRatio(14),
                                // color: '#666666',
                                color: "#000",
                            },
                        },
                        itemStyle: {
                            borderRadius: [this.graphicRatio(5),this.graphicRatio(5), 0, 0],
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: that.graphicsData.colordata[0],
                            }, {
                                offset: 1,
                                color: that.graphicsData.colordata[1],
                            }])
                        },
                        data: that.graphicsData.semester1,
                    },
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
    },
}
</script>
<style scoped lang="less">
.excellent_rates_grap {
    width: 100%;
    height: 100%;
}
</style>